/* eslint-disable max-len */

export const colors = {
  brand: '#292D78',
  brandDark: '#101459',
  skyblue: "#E7F0FE",
  lilac: '#9d7cbf',
  accent: '#ffb238',
  success: '#37b635',
  warning: '#ec1818',
  ui: {
    bright: '#e0d6eb',
    light: '#f5f3f7',
    whisper: '#fbfafc'
  },
  code: '#fcf6f0',
  blueGradient: 'linear-gradient(191.72deg, #E2EDFC 0%, #E9F1FE 100%)',
  gray: {
    dark: '#262626',
    copy: 'hsla(270, 15.797828016000002%, 0%, 0.88)',
    calm: '#ADADAD',
    light: '#F3F3F3'
  },
  white: '#fff',
  black: '#000'
}

export const fonts = {
  sansSerif: 'Lato, sans-serif',
  serif: 'Georgia, "Times New Roman", Times, serif',
  monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace'
}

export const bp: { [key: string]: number } = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
}

export const mq = (n: string) => {
  const bpArray = Object.keys(bp).map(key => [key, bp[key]])
  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
  maxInner: 1310,
  maxOuter: 2000
}

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2
  },
  containerPadding: 1.5
}

export const heights = {
  header: 70,
  headerInitial: 100
}
