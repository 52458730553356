import * as React from 'react'
import styled from '@emotion/styled'

import { widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
`

interface ContainerProps {
  id?: string
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className, id }) => (
  <StyledContainer id={id} className={className}>
    {children}
  </StyledContainer>
)

export default Container
